<template>
  <div>
    <home></home>
  </div>
</template>

<script>
import home from "./view/home.vue";
export default {
  data() {
    return {};
  },
  components: {
    home,
  },
  methods: {},
};
</script>
